import * as React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import { lazy as loadable } from '@loadable/component'

import FeaturedGame from './featured-game'

const LoginBox = loadable(() => import('./login-box'))

export function LoginPage() {
  const i18n = I18n.useI18n()
  return (
    <FeaturedGame>
      <Common.Box
        position="relative"
        display="flex"
        flexDirection="column"
        justifyContent={['flex-start', 'center']}
        alignItems="center"
        width="100%"
        flexGrow="1"
        flexShrink="1"
        maxWidth="330px"
        zIndex="1"
        pb="200px"
      >
        <Common.Box
          as="h1"
          fontSize={7}
          fontWeight="bold"
          py={1}
          textAlign="center"
          fontFamily="head"
        >
          {i18n.translate('login-page.welcome-back')}
        </Common.Box>
        <LoginBox />
      </Common.Box>
    </FeaturedGame>
  )
}

// For @loadable/components
export default LoginPage
