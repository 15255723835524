import * as R from 'ramda'
import { connect } from 'react-redux'

import * as api from '@rushplay/api-client'

import * as gameCatalog from '../game-catalog'
import * as configuration from '../configuration'

import { FeaturedGameContainer as Component } from './featured-game-container'

const FeaturedGame = R.compose(
  configuration.withClientType,
  connect(
    state => {
      const getGameCatalogIndex = gameCatalog.createGetGameCatalogIndex()
      const getGameSections = gameCatalog.createGetGameSections()
      const catalogId = R.path(
        ['id'],
        R.head(getGameCatalogIndex(state.gameCatalog, { catalog: 'featured' }))
      )
      const section = R.head(
        getGameSections(state.gameCatalog, { category: catalogId })
      )
      const gameIds = R.pathOr([], ['games'], section)
      const games = R.map(
        id => gameCatalog.getGame(state.gameCatalog, { id }),
        gameIds
      )

      return {
        imgproxyUrl: configuration.getImgproxyUrl(state.configuration),
        games: R.isNil(R.head(games)) ? [] : games,
      }
    },
    (dispatch, props) => ({
      onFetch: () =>
        dispatch(
          api.fetchGameCatalog('featured', {
            cacheFor: process.browser ? 120000 : null,
            success: res => [
              api.fetchGameCatalogCategories(
                {
                  categoryId: R.path(['categories', 0, 'id'], res.value),
                  clientType: props.clientType,
                },
                {
                  cacheFor: process.browser ? 60000 : null,
                  success: catRes => [
                    gameCatalog.updateGameCatalogCategories({
                      [R.path(['categories', 0, 'id'], res.value)]: {
                        sections: R.pluck(
                          'id',
                          R.path(['sections'], catRes.value)
                        ),
                      },
                    }),
                    gameCatalog.updateGameCatalogSections(
                      R.map(
                        section =>
                          R.merge(section, {
                            games: R.pluck('id', R.path(['games'], section)),
                          }),
                        R.indexBy(
                          R.path(['id']),
                          R.path(['sections'], catRes.value)
                        )
                      )
                    ),
                    gameCatalog.updateGameCatalogGames(
                      R.reduce(
                        (games, section) =>
                          R.merge(
                            games,
                            R.indexBy(
                              R.path(['id']),
                              R.path(['games'], section)
                            )
                          ),
                        {},
                        R.path(['sections'], catRes.value)
                      )
                    ),
                  ],
                  version: 1,
                }
              ),
              gameCatalog.updateGameCatalog(
                'featured',
                R.pluck('id', R.path(['categories'], res.value))
              ),
              gameCatalog.updateGameCatalogCategories(
                R.indexBy(R.path(['id']), R.path(['categories'], res.value))
              ),
            ],
            version: 1,
          })
        ),
    })
  )
)(Component)

// For @loadable/components
export default FeaturedGame
