import * as React from 'react'
import * as EmotionTheming from 'emotion-theming'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import { lazy as loadable } from '@loadable/component'

import FeaturedGame from '../featured-game'
import { HtmlContent } from '../html-content'
import { InformativeSection } from '../informative-section'

const LoginBox = loadable(() => import('../login-box'))
const LandingPageCategory = loadable(() => import('../landing-page-category'))

function WelcomeOffer(props) {
  const i18n = I18n.useI18n()
  const theme = EmotionTheming.useTheme()

  return (
    <>
      <Common.Box
        backgroundImage={`linear-gradient(
          to top,
          ${theme.colors['finland-welcome-offer-gradient-start']} 0%,
          ${theme.colors['finland-welcome-offer-gradient-stop']} 100%
        )`}
        width="100%"
        textAlign="center"
        p={1}
        borderRadius="8px"
      >
        <Common.Text
          as="h3"
          fontSize={4}
          textAlign="center"
          fontFamily="head"
          color="finland-welcome-offer-text"
          fontWeight="bold"
          lineHeight="1.5"
          textTransform="uppercase"
        >
          {i18n.translate('landing-page.welcome-offer-finland')}
        </Common.Text>
        <Common.Box
          fontSize="42px"
          textAlign="center"
          fontFamily="head"
          lineHeight="1.14"
          color="static-white"
          fontWeight="bold"
          textShadow={`3px 0 1px ${theme.colors['finland-welcome-offer-shadow']},
                    0 3px 1px ${theme.colors['finland-welcome-offer-shadow']},
                    -3px 0 1px ${theme.colors['finland-welcome-offer-shadow']},
                    0 -3px 1px ${theme.colors['finland-welcome-offer-shadow']},
                    2px 2px 1px ${theme.colors['finland-welcome-offer-shadow']},
                    2px -2px 1px ${theme.colors['finland-welcome-offer-shadow']},
                    -2px 2px 1px ${theme.colors['finland-welcome-offer-shadow']},
                    -2px -2px 1px ${theme.colors['finland-welcome-offer-shadow']},
                    0 4px 16px ${theme.colors['finland-welcome-offer-shadow']}`}
        >
          <Common.Box>
            {i18n.translate('landing-page.money-bonus-with-up-to', {
              bonusPercentage: props.bonusPercentage,
              amountCents: props.amountCents,
            })}
          </Common.Box>
          {i18n.hasTranslation('landing-page.additional-reward') && (
            <Common.Box fontSize="16px" fontWeight="600" lineHeight="1.75">
              {i18n.translate('landing-page.additional-reward', {
                maxFreespins: props.maxFixedFreespins,
                maxFeatureTriggers: props.maxFeatureTriggers,
              })}
            </Common.Box>
          )}
        </Common.Box>
      </Common.Box>
      <Common.Box
        alignSelf="center"
        borderLeftColor="transparent"
        borderRightColor="transparent"
        borderBottomColor="finland-welcome-offer-gradient-start"
        borderTopColor="finland-welcome-offer-gradient-start"
        borderStyle="solid"
        borderWidth="18px 18px 0px 18px"
        height="0px"
        width="0px"
        mb={1}
      />
    </>
  )
}

export function LandingPage(props) {
  const i18n = I18n.useI18n()

  return (
    <Common.Box display="flex" flexDirection="column" width="100%">
      <Common.Box>
        <FeaturedGame variant="centered">
          <Common.Box
            display="flex"
            position="relative"
            flexDirection="column"
            justifyContent={['flex-start', 'center']}
            alignItems="center"
            width="100%"
            flexGrow="1"
            flexShrink="1"
            maxWidth="330px"
            zIndex="1"
          >
            {props.authenticated || props.returningPlayer ? (
              <Common.Box
                as="h1"
                fontSize={6}
                fontWeight="bold"
                py={1}
                textAlign="center"
                fontFamily="head"
              >
                {i18n.translate('landing-page.welcome')}
              </Common.Box>
            ) : (
              <WelcomeOffer
                bonusPercentage={props.bonusPercentage}
                amountCents={props.maxBonusAmount}
                maxFixedFreespins={props.maxFixedFreespins}
                maxFeatureTriggers={props.maxFeatureTriggers}
              />
            )}
            <Common.Box
              as="h3"
              fontSize={3}
              fontWeight="bold"
              pb={1}
              textAlign="center"
              fontFamily="head"
            >
              {i18n.translate('landing-page.start')}
            </Common.Box>
            <LoginBox />
          </Common.Box>
          <Common.Box px={5} pt={[null, null, 7]} pb={[5, 5, '0px']} zIndex="2">
            <InformativeSection />
          </Common.Box>
        </FeaturedGame>
      </Common.Box>
      <Common.Box maxWidth="1080px" width="100%" mx="auto">
        <LandingPageCategory />
      </Common.Box>
      <Common.Box
        p={2}
        maxWidth="1080px"
        mx="auto"
        textAlign="left"
        fontSize={2}
      >
        <HtmlContent
          html={{
            __html: i18n.translate('landing-page.seo-content'),
          }}
        />
      </Common.Box>
    </Common.Box>
  )
}

LandingPage.propTypes = {
  bonusPercentage: PropTypes.number.isRequired,
  maxBonusAmount: PropTypes.number.isRequired,
  maxFixedFreespins: PropTypes.number,
  maxFeatureTriggers: PropTypes.number,
  authenticated: PropTypes.bool,
  returningPlayer: PropTypes.bool,
}

WelcomeOffer.propTypes = {
  bonusPercentage: PropTypes.number,
  amountCents: PropTypes.number,
  maxFixedFreespins: PropTypes.number,
  maxFeatureTriggers: PropTypes.number,
}

// For @loadable/components
export default LandingPage
